<template>
  <HxTable v-model:columns="columns" :reload="loadData">
    <template #search>
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="">
          <a-select v-model:value="searchForm.promoterType" :options="extensionTypeEnum" placeholder="推广类型" allowClear style="width:140px" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.promoterConfigName" placeholder="推广人" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.mobile" placeholder="电话号" />
        </a-form-item>
        <a-space>
          <a-button class="searchLoading-button" :loading="loading" type="primary" @click="onSearch" :disabled="loading">
            查询
          </a-button>
          <a-button @click="reset">
            重置
          </a-button>
        </a-space>
      </a-form>
    </template>
    <!--    <template #toolbarLeft>-->
    <!--      <a-space>-->
    <!--        <a-button @click="handleCope()">-->
    <!--          推广码-->
    <!--        </a-button>-->
    <!--      </a-space>-->
    <!--    </template>-->
    <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #operation="{ record }">
        <div class="edit" @click="onSeeDetail(record)">查看明细</div>
      </template>
    </a-table>

    <!-- 推广小程序码 -->
    <a-modal v-model:visible="QRC" title="推广二维码" ok-text="下载推广二维码" @ok="downQRC()">
      <div class="QRC">
        <div>
          <a-spin v-show="QRCLoading" tip="Loading..." />
        </div>
        <!--        <div v-show="!QRCLoading" style="text-align:center">-->
        <!--            推广人-->
        <!--        </div>-->
        <img v-show="!QRCLoading" :src="QRCurl" style="width:50%" />
      </div>
    </a-modal>
    <a-drawer placement="right" :closable="false" width='80%' v-model:visible="detailShow">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane :key="1" tab="订单列表">
          <Order v-if="detailShow && activeKey===1" :userId="userId"></Order>
        </a-tab-pane>
        <a-tab-pane :key="2" tab="推广列表" force-render>
          <Record v-if="detailShow && activeKey===2" :userId="userId" />
        </a-tab-pane>
        <a-tab-pane :key="3" tab="询价列表" force-render>
          <InquiryTable v-if="detailShow && activeKey===3" v-model:appletUserId="userId" />
        </a-tab-pane>
      </a-tabs>
    </a-drawer>
  </HxTable>

</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { promoterPage } from '@/api/BEnd/applet'
import HxTable from '@/components/HxTable'
import Record from './record'
// import { message } from 'ant-design-vue'
import { QRCurl } from '@/api/transport/inspection'
import Order from '../applet/order'
import InquiryTable from '../inquiry/table'
export default {
  components: {
    HxTable,
    Record,
    Order,
    InquiryTable
  },
  setup () {
    const state = reactive({
      userId: null,
      detailShow: false,
      activeKey: 1,
      QRCurl: '',
      QRCLoading: false,
      QRC: false,
      loading: false,
      listData: [],
      searchForm: {
      },
      columns: [
        {
          title: '推广人姓名',
          align: 'center',
          dataIndex: 'promoterConfigName'
        },
        {
          title: '电话号码',
          dataIndex: 'mobile',
          align: 'center'
        },
        {
          title: '推广类型',
          dataIndex: 'promoterType.label',
          align: 'center'
        },
        {
          title: '注册时间',
          dataIndex: 'regTime',
          align: 'center'
        },
        {
          title: '操作',
          align: 'center',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      // 推广类型
      extensionTypeEnum: [
        {
          label: '内部员工',
          value: 1
        },
        {
          label: '外部地推',
          value: 2
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    // 搜索选择优惠券类型
    const setPromoterType = (e, v) => {
      state.searchForm.promoterType = v
    }
    const onSearch = () => {
      state.loading = true
      state.pagination.current = 1
      loadData()
    }


    const loadData = () => {
      state.loading = true
      promoterPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          state.loading = false
        })
    }

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = {}
      loadData()
    }
    // 查看明显
    const onSeeDetail = (r) => {
      state.userId = r.id
      state.detailShow = true
    }
    onMounted(() => {
      loadData()
    })

    // 生成小程序码
    const handleCope = () => {
      state.QRC = true
      state.QRCLoading = true
      const wXprogramQRcode = { scene: state.transTruckId, type: 3 }
      setTimeout(() => {
        QRCurl(wXprogramQRcode)
          .then(res => {
            if (res.code === 10000) {
              state.QRCurl = 'data:image/png;base64,' + res.data
              state.QRCLoading = false
            }
          })
          .catch(err => console.log(err))
      }, 3000)
    }
    // 下载二维码
    const downQRC = () => {
      const image = new Image()
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function () {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        const url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
        const a = document.createElement('a') // 生成一个a元素
        const event = new MouseEvent('click') // 创建一个单击事件
        a.download = '二维码' || 'pic' // 设置图片名称
        a.href = url // 将生成的URL设置为a.href属性
        a.dispatchEvent(event) // 触发a的单击事件
      }
      image.src = state.QRCurl
    }

    return {
      ...toRefs(state),
      reset,
      onSearch,
      loadData,
      setPromoterType,
      handleTableChange,
      onSeeDetail,
      handleCope,
      downQRC
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
